.header {
  display: flex;
  justify-content: flex-end;
  padding: 18px 18px 0;
  height: 40px;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;

  svg {
    fill: #fff;
    height: 17px;
    width: 28px;
    margin-bottom: -3px;
    margin-right: 12px;
  }
}

.logo {
  display: flex;
  justify-content: center;
  margin-top: 3vh;
  height: 160px;
}

.slot-container {
  background-image: url("/img/machine.png");
  // background-size: contain;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 610px;
  width: 875px;
  min-width: 800px;
}

.contaner-positioner {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 10;
}

.collect-container {
  display: flex;
  justify-content: center;
}

.cluster {
  color: #ffffff99;
  font-size: 12px;
  text-align: right;
  padding: 8px 21px 0;
}
